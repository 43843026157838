import { Text, Paper } from '@mantine/core';

const TextMessage = ({content}) => {

  return(
    <div className="text-message">
      <Paper withBorder shadow="sm" radius="md" p="md" >
        <Text>{content.message}</Text>
      </Paper>
    </div>
  )
};

export default TextMessage;