import { Group, Stack, Text, Timeline } from '@mantine/core';
import { IconGpsFilled, IconCircleLetterGFilled, IconCircleLetterNFilled } from '@tabler/icons-react';

import './Day.css';

const Day = (props) => {

  const day_items = props.children.map((child, idx) => {
    if (child.props.content.from === 'inreach')
      return <Timeline.Item bullet={<IconGpsFilled />} key={idx}>{child}</Timeline.Item>
    
    if (child.props.content.from === 'greg')
      return <Timeline.Item bullet={<IconCircleLetterGFilled />} key={idx}>{child}</Timeline.Item>
    
    if (child.props.content.from === 'nicole')
      return <Timeline.Item bullet={<IconCircleLetterNFilled />} key={idx}>{child}</Timeline.Item>
    
    return <Timeline.Item key={idx}>{child}</Timeline.Item>
  });

  return (
    <div className="day-container">
      <Group justify='center'>
        <Text size="xl">{props.date}</Text>
      </Group>
      <Stack
        align="flex-start"
        justify="space-between"
        gap="md"
      >
        <Timeline color="teal" active={props.children.length} bulletSize={30} >
          {day_items}
        </Timeline>
      </Stack>
    </div>
  )
}

export default Day;