import { useEffect, useState } from 'react';
import { AppShell } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';

import HikeMap from '../components/HikeMap.js';
import HikeHeader from '../components/HikeHeader.js';
import HikeTimeline from '../components/HikeTimeline.js';
import TimelineSkeleton from '../components/TimelineSkeleton.js';

const useHikeData = (id) => {
  const [details, setDetails] = useState();
  const [updates, setUpdates] = useState();

  useEffect(() => {
    const getData = async () => {
      // grab from multiple APIs in parallel
      const detailsUrl = "https://dri1ojntq5.execute-api.us-east-2.amazonaws.com/backcountry/trips";
      const updatesUrl = "https://dri1ojntq5.execute-api.us-east-2.amazonaws.com/backcountry/trips/" + id;
      const results = (
        await Promise.all([
          fetch(detailsUrl),
          fetch(updatesUrl),
        ])
      ).map((r) => r.json());

      // pull the responses out
      const [detailsResult, updatesResult] = await Promise.all(results);
      setDetails(detailsResult.items.currentTrip); // works for now - todo restructure the api
      setUpdates(updatesResult.items);
    };

    getData();
  }, [id]);

  return { details, updates };
}

const Thruhike = () => {

  // someday I'll extend, but for now fix the site to our current trip
  const tripid = "longtrail";

  // const {tripid} = useParams(); // route includes the tripid
  const {details, updates} = useHikeData(tripid);
  const [map_opened, { toggle }] = useDisclosure();
  
  return (
    <div id="thruhike-view-container">
      <AppShell
        header={{ height: 60 }}
        navbar={{ width: '40vw', breakpoint: 'sm', collapsed: { mobile: !map_opened } }}
        padding="lg"
      >

        <AppShell.Header>
          <HikeHeader hikename={details && details.name} map_opened={map_opened} toggle={toggle} />
        </AppShell.Header>
        
        <AppShell.Navbar p="md">
          <HikeMap checkins={updates && updates.filter((item)=>item.coordinates)} routeGpxFile="/maps/vt-long-trail.gpx" />
        </AppShell.Navbar>
        
        <AppShell.Main>
          { updates ?
            <HikeTimeline tripUpdates={updates} /> 
            :
            <TimelineSkeleton />
           }
        </AppShell.Main>
      
      </AppShell>
    </div>
  )
};

export default Thruhike;