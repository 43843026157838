// helper functions

// wrapper function for generating a date/time string
export function generateDateStr(dateStr, localeOptions) {
  return new Date(dateStr).toLocaleString("en-US", localeOptions)
}

// get user information from session storage
export function getUserDetails() {
  const tokenString = sessionStorage.getItem('token');
  return JSON.parse(tokenString);
}