import { Group } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { IconPencil } from '@tabler/icons-react';

import TextMessage from './TextMessage.js'
import Timestamp from './Timestamp.js';
import EditMessageModal from './EditMessageForm.js';
import MediaMessage from './MediaMessage.js';
import { getUserDetails } from '../../helpers.js';

import './MessageContainer.css';

const Message = ({content}) => {

  // Modal form to edit a message
  const [form_opened, { open, close }] = useDisclosure(false);
  const userDetails = getUserDetails();

  return(
    <div className="message-container">

      {content.image ? <MediaMessage content={content} /> : <TextMessage content={content} />}
      
      <Group justify="space-between">
        
        <Group justify="flex-start">          
          <Timestamp time={content.timestamp} zone="America/New_York" />
        </Group>
        
        { 
          userDetails.isAdmin && 
          <Group justify="flex-end">
            <IconPencil 
              style={{ width: '70%', height: '70%', cursor: 'pointer', color: 'lightgrey' }} stroke={1.5}
              onClick={open}
              />
          </Group>
        }

      </Group>
      
      <EditMessageModal content={content} opened={form_opened} close={close} />
    </div>
  )
};

export default Message;