import { Stack, Group, Skeleton, Table, Paper } from "@mantine/core";

const TimelineSkeleton = () => {

  const messageSkeleton = 
    <Table withRowBorders={false}>
      <Table.Tbody>
        <Table.Tr>
          <Table.Td w={80}>
            <Skeleton circle height={35} mb={55} />
          </Table.Td>
          <Table.Td>
            <Paper withBorder shadow="sm" radius="md" p="md" >
              <Skeleton height={10} mt={8} />
              <Skeleton height={10} mt={8} />
              <Skeleton height={10} mt={8} width="70%" />
            </Paper>
          </Table.Td>
        </Table.Tr>
      </Table.Tbody>
    </Table>

  return (
    <div className="day-container">
      <Group justify='center'><Skeleton height={16} mt="sm" mb="lg" ml={60} width="50%" /></Group>
      <Stack align="flex-start" justify="space-between" gap="xl" >
        {messageSkeleton}
        {messageSkeleton}
        {messageSkeleton}
      </Stack>
    </div>
  );
};

export default TimelineSkeleton;