import Day from '../components/Day.js';
import MessageContainer from './Messages/MessageContainer.js'

import { generateDateStr } from '../helpers.js';

const HikeTimeline = ({tripUpdates}) => {

  // separate the trip into days
  let tripDays = []
  let lastDate = '';
  tripUpdates.forEach(item => {

    // todo - handle timezone per trip? depending on location?
    const dateLabel = generateDateStr(item.timestamp, {
      weekday: "long",
      year: "numeric",
      month: "long",
      day: "numeric",
      timeZone: "America/New_York"
    });

    if (dateLabel !== lastDate) {
      // start a new day
      tripDays.push({ date: dateLabel, items: []});
    }
    lastDate = dateLabel;
    tripDays[ tripDays.length - 1 ].items.push(item);
  });

  return (
    tripDays.map((day, idx) => {
      return(
        <Day date={day.date} key={idx}>
          {day.items.map((item, idx) => {
            return <MessageContainer content={item} key={idx} />
          })}
        </Day>
      )
    })
  )
};

export default HikeTimeline;