import { useEffect } from 'react';
import { Map, useMapsLibrary, useMap } from '@vis.gl/react-google-maps';

import MapMarker from './MapMarker.js';

const HikeMap = ({routeGpxFile, checkins}) => {

  // If a hiking route is provided, load the maps libraries
  // necessary to display the route and set the map bounds
  const coreLibrary = useMapsLibrary('core');
  const mapsLibrary = useMapsLibrary('maps');
  let map = useMap();
  
  useEffect(() => {
    if (!(coreLibrary && mapsLibrary && map && routeGpxFile)) return;

    fetch(routeGpxFile)
      .then(response => response.text())
      .then(str => (new window.DOMParser()).parseFromString(str, "text/xml"))
      .then(doc => {
        let points = [];
        let bounds = new coreLibrary.LatLngBounds();
      
        const nodes = [...doc.getElementsByTagName('trkpt')];
        nodes.forEach(node => {
          const lat = node.getAttribute("lat");
          const lon = node.getAttribute("lon");
              
          const p = new coreLibrary.LatLng(lat, lon);
          points.push(p);
          bounds.extend(p);
        });

        const poly = new mapsLibrary.Polyline({
          path: points,
          strokeColor: "#0000FF",
          strokeOpacity: 1,
          strokeWeight: 2
        });
          
        // Add the route to the map and update the bounds
        poly.setMap(map);
        map.fitBounds(bounds);
      });
  }, [coreLibrary, mapsLibrary, map, routeGpxFile]);

  return (
    <Map mapId="9410fc9b37c4ca0c" defaultCenter={{ lat: 44.0407, lng: -72.7093 }} defaultZoom={8}>
      {checkins && checkins.map((checkin, idx) => {
        return <MapMarker key={idx} coords={checkin.coordinates} timestamp={checkin.timestamp} />
      })}
    </Map>
  );
};

export default HikeMap;