import { createBrowserRouter, RouterProvider, Outlet } from "react-router-dom";

import Page from "./components/Page";
import Error from "./views/Error";
import Thruhike from "./views/Thruhike";
import Login from './views/Login';
import useToken from './hooks/useToken';

export default function App() {
  const { token, setToken } = useToken();
  
  if(!token) {
    return <Login setToken={setToken} />
  }
  
  const router = createBrowserRouter([
    {
      // layout route - wrap in the Page component
      element: <Page><Outlet /></Page>,
      errorElement: <Error />
    },
    {
      path: "/",
      element: <Thruhike />,
    },
  ]);

  return <RouterProvider router={router} fallbackElement={<p>Loading...</p>} />;
};