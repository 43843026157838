import { useState } from 'react';
import { Center, Fieldset, TextInput, PasswordInput, Group, Button, Text, LoadingOverlay, em } from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';

import './Login.css';

const Login = ({setToken}) => {
  const [usernameError, setUsernameError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [loginError, setLoginError] = useState(false);
  
  const [loading, setLoading] = useState(false);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  const isMobile = useMediaQuery(`(max-width: ${em(750)})`);

  const loginUser = async (credentials) => {

    return (fetch('https://dri1ojntq5.execute-api.us-east-2.amazonaws.com/backcountry/login', {
      method: 'POST',
      body: JSON.stringify(credentials)
    })
    .then(function(response) {
      if (response.ok)
        return response.json()
      setLoading(false);
      setLoginError(true);
      return;
      })
    );
  }

  const handleSubmit = async e => {
    e.preventDefault();
    setLoginError(false);
    
    // form validation
    !username ? setUsernameError("Enter a valid username") : setUsernameError("");
    !password ? setPasswordError("Enter a valid password") : setPasswordError("");
    
    if (username && password) {
      setLoading(true);
      const token = await loginUser({
        username,
        password
      });
      if (token)
        setToken(token);
    }
  }

  const updateUsername = v => {
    setUsernameError(false);
    setUsername(v);
  }

  const updatePassword = v => {
    setPasswordError(false);
    setPassword(v);
  }
    
  return (
    <div id="login-view">
      <Center >
        <form onSubmit={handleSubmit}>
        <LoadingOverlay visible={loading} zIndex={1000} overlayProps={{ radius: "sm", blur: 2 }} />
        <Fieldset w={380}>
          <TextInput 
            onChange={e => updateUsername(e.currentTarget.value)}
            label="Username"
            error={usernameError ? usernameError : false}
            size={isMobile ? "md" : "sm"}
          />
          <PasswordInput 
            onChange={e => updatePassword(e.currentTarget.value)}
            label="Password" 
            error={passwordError ? "Enter a valid password" : false}
            size={isMobile ? "md" : "sm"}
          />
          <Group justify="flex-end" mt="md">
            { loginError && <Text size="xs" c="red">Did not recognize username or password</Text> }
            <Button type='submit'>Login</Button>
          </Group>
        </Fieldset>
        </form>
      </Center>
    </div>
  )
}

export default Login;