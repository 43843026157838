import { AspectRatio, Image, Modal, Text } from '@mantine/core';
import { IconMap } from '@tabler/icons-react';

const HelpModal = ({opened, close}) => {

  return(
    <Modal size="lg" opened={opened} onClose={close} title="Welcome to our site!">
      <Text>
        We put this site together to document our thruhiking journeys and to give our friends 
        and family a way to watch our progress on the trails. Thank you for being here and supporting us!!
      </Text>

      <Text mt="lg">
        A few notes on using this site:
      </Text>
      <ul>
        <li>Our updates, checkins, photos, etc will come in as we post them from the trail. Check back occasionally to see how it's going!</li>
        <li style={{marginTop: '.5em'}}>The map has a solid blue line--that's our route! As we post GPS coordinates to the map they'll appear as red dots, so you'll see us making our way along the trail.</li>
        <li style={{marginTop: '.5em'}}>If you're on mobile, you can open the map using the map icon <IconMap stroke={1.2} /> in the top left.</li>
      </ul>
      
      <Text mt="lg">Thank you, we love you, and we can't wait to share our journey with you!</Text>
      <Text mb="xl">-Nicole & Greg</Text>
      <AspectRatio ratio={403 / 302} maw={300} mx="auto">
        <Image src="/images/cheesin.jpg" />
      </AspectRatio>

    </Modal>
  );
};

export default HelpModal;