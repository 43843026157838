import { useState, useCallback } from 'react';
import { Text } from '@mantine/core';
import { AdvancedMarker, InfoWindow, useAdvancedMarkerRef } from '@vis.gl/react-google-maps';

import { generateDateStr } from '../helpers';

const MapMarker = ({coords, timestamp}) => {
  const position = {lat: Number(coords[0]), lng: Number(coords[1])};

  const [markerRef, marker] = useAdvancedMarkerRef();
  const [infoWindowShown, setInfoWindowShown] = useState(false);

  const handleMarkerClick = useCallback(
    () => setInfoWindowShown(isShown => !isShown),
    []
  );
  const handleClose = useCallback(() => setInfoWindowShown(false), []);

  const dateLabel = generateDateStr(timestamp, {
    weekday: "short",
    year: "numeric",
    month: "short",
    day: "numeric",
    timeZone: "America/New_York"
  });
  
  const timeLabel = generateDateStr(timestamp, {
    hour: "numeric",
    minute: "numeric",
    timeZoneName: "short",
    timeZone: "America/New_York",
  });

  return (
    <>
      <AdvancedMarker 
        ref={markerRef}
        position={position} 
        onClick={handleMarkerClick}
      >
        <svg width="12" height="12" xmlns="http://www.w3.org/2000/svg">
          <circle cx='5' cy='5' r='5' stroke='white' strokeWidth='1' fill='#b22222' />
        </svg>
      </AdvancedMarker>

      {infoWindowShown && (
        <InfoWindow anchor={marker} onClose={handleClose} headerContent={<h3>GPS check-in</h3>}>
          <Text size="sm">({position.lat}, {position.lng})</Text>
          <Text size="xs" mt="sm">{dateLabel} at {timeLabel}</Text>
        </InfoWindow>
      )}
    </>
  );
};

export default MapMarker;