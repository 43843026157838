import React from 'react';
import ReactDOM from 'react-dom/client';
import { MantineProvider } from '@mantine/core';
import { APIProvider } from '@vis.gl/react-google-maps';

import App from './App';

// core styles are required for all packages
import '@mantine/core/styles.css';

import './index.css';

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
    <MantineProvider>
      <APIProvider apiKey="AIzaSyAiAvavEY-yGV24p4mVh61X19U98xHBWpg">
        <React.StrictMode>
          <App />
        </React.StrictMode>
      </APIProvider>
    </MantineProvider>
);