import { Link } from 'react-router-dom';
import { Center, Text, Card, Image, Stack } from '@mantine/core';

import './Error.css';

const Error = () => {

  return (
    <div id="error-view">
      <Center>
          <Card shadow="sm" padding="lg" radius="md" w={400} withBorder>
            <Card.Section>
              <Image
                src="/images/error.jpg"
                height={200}
                alt=""
              />
            </Card.Section>

            <Stack align='center'>
              <Text mt={10} fw={500}>Hmm... You Seem Lost</Text>
              <Text size="sm" c="dimmed">
                This page doesn't seem to exist--pull out your map and compass and backtrack to one that does.
              </Text>
              <Link style={{color: "dodgerblue"}} to="/" ><Text size="sm">Back to safety</Text></Link>
            </Stack>
          </Card>
      </Center>
    </div>
  )
}

export default Error;