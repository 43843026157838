import { Text, Image, SimpleGrid, Card } from '@mantine/core';

const MediaMessage = ({content}) => {

  return(
    <div className="trip-day-media-message">
      <Card withBorder shadow="sm" radius="md">
        <Text mt="sm">
          {content.message}
        </Text>
        <Card.Section inheritPadding mt="sm" pb="md">
          <SimpleGrid cols={1}>
            <Image src={"/" + content.image} radius="sm" />
          </SimpleGrid>
        </Card.Section>
      </Card>      
    </div>
  )
};

export default MediaMessage;