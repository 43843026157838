import { useState } from 'react';
import { Modal, TextInput, LoadingOverlay, Fieldset, Group, Button } from '@mantine/core';

const AddUserModal = ({opened, close}) => {

  const [loading, setLoading] = useState(false);
  const [submitted_username, setUsername] = useState("");
  const [submitted_password, setPassword] = useState("");

  const addUser = async (details) => {
    return (fetch('https://dri1ojntq5.execute-api.us-east-2.amazonaws.com/backcountry/user', {
      method: 'POST',
      body: JSON.stringify(details)
    })
    .then(function(response) {
      setLoading(false);
      if (!response.ok)
        // todo - error handling?
        return response.json()

      // on success (201), clear the values
      setUsername("");
      setPassword("");
      return;
      })
    );
  }

  const handleSubmit = async e => {
    e.preventDefault();

    if (submitted_username && submitted_password) {
      setLoading(true);

      const tokenString = sessionStorage.getItem('token');
      const userToken = JSON.parse(tokenString);
      const username = userToken.username;
      const token = userToken.token;
        
      const response = await addUser({
        submitted_username,
        submitted_password,
        username,
        token
      });
      if (response)
        console.log(response);
    }
  }

  return(
    <Modal opened={opened} onClose={close} title="Add User">
      <form onSubmit={handleSubmit}>
        <LoadingOverlay visible={loading} zIndex={1000} overlayProps={{ radius: "sm", blur: 2 }} />
        <Fieldset >
          <TextInput
            onChange={e => setUsername(e.currentTarget.value)}
            label="Username"
            value={submitted_username}
          />
          <TextInput
            onChange={e => setPassword(e.currentTarget.value)}
            label="Password"
            value={submitted_password}
          />
          <Group justify="flex-end" mt="md">
            <Button type='submit'>Add User</Button>
          </Group>
        </Fieldset>
      </form>
    </Modal>
  );
};

export default AddUserModal;