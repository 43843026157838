import { useState } from 'react';
import { Modal, LoadingOverlay, Group, Text, Button, TextInput, Textarea, em } from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';

import { getUserDetails } from '../../helpers';

const EditMessageModal = ({content, opened, close}) => {

  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [message, setMessage] = useState(content.message);
  const isMobile = useMediaQuery(`(max-width: ${em(750)})`);
  
  const handleSubmit = async e => {
    e.preventDefault();
    
    setSuccess(false);
    setLoading(true);
    
    const userDetails = getUserDetails();
    const trip = content.trip;
    const timestamp = content.timestamp;
    const newMessage = message;
    const username = userDetails.username;
    const token = userDetails.token;
      
    const response = await updateMessage({
      trip,
      timestamp,
      newMessage,
      username,
      token
    });
    if (response)
      console.log(response);
  }

  const updateMessage = async (details) => {
    return (fetch('https://dri1ojntq5.execute-api.us-east-2.amazonaws.com/backcountry/update', {
      method: 'PUT',
      body: JSON.stringify(details)
    })
    .then(function(response) {
      setLoading(false);
      if (!response.ok)
        // todo - error handling?
        return response.json()
      
      // on success
      setSuccess(true);
    })
  )};

  const handleConfirmDelete = () => {
    setConfirmDelete(true);
  }

  const cancelDelete = () => {
    setConfirmDelete(false);
  }
  
  const handleDelete = async (details) => {
    setLoading(true);
    
    const userDetails = getUserDetails();
    const trip = content.trip;
    const timestamp = content.timestamp;
    const username = userDetails.username;
    const token = userDetails.token;

    const response = await deleteUpdate({
      trip,
      timestamp,
      username,
      token
    });
    if (response)
      console.log(response);
    setLoading(false);
  };

  const deleteUpdate = async (details) => {
    return (fetch('https://dri1ojntq5.execute-api.us-east-2.amazonaws.com/backcountry/update', {
      method: 'DELETE',
      body: JSON.stringify(details)
    })
    .then(function(response) {
      setLoading(false);
      if (!response.ok)
        // todo - error handling?
        return response.json()
      
      // on success
      closeModal();
    })
  )};

  const closeModal = () => {
    // reset the message state
    setSuccess(false);
    setLoading(false);
    setConfirmDelete(false);
    setMessage(content.message);
    close();
  };

  return(
    <Modal opened={opened} onClose={closeModal} title="Edit Message">
      <form onSubmit={handleSubmit}>
        <LoadingOverlay visible={loading} zIndex={1000} overlayProps={{ radius: "sm", blur: 2 }} />

        <TextInput disabled label="Trip" value={content.trip} />

        <TextInput disabled label="Timestamp" value={content.timestamp} mt="md" />

        {content.coordinates && 
          <TextInput disabled label="Coordinates" value={content.coordinates} mt="md" /> }

        <TextInput disabled label="From" value={content.from} mt="md" />

        {content.image &&
          <Textarea disabled label="Image URL" value={content.image} mt="md" /> }

        <Textarea 
          label="Message" 
          defaultValue={content.message} 
          size={isMobile ? 'md' : 'sm'}
          onChange={e => setMessage(e.currentTarget.value)}
          mt="md"
          autosize
        />

        <Group justify="space-between" mt="md">
          <Group justify="flex-start">
            {confirmDelete ? 
              <>
                <Button color='red' onClick={handleDelete}>Really Delete</Button>
                <Button onClick={cancelDelete}>Cancel</Button>
              </>
              : 
              <Button variant='light' color='red' onClick={handleConfirmDelete}>Delete</Button>
            }
          </Group>
          
          { success && 
            <Text size="xs" c="green">Successfully updated message!</Text>
          }
          
          <Group justify='flex-end'>
            <Button type='submit'>Submit</Button>
          </Group>
        </Group>

      </form>
    </Modal>
  );
};

export default EditMessageModal;