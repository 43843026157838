import { Text } from '@mantine/core';

import { generateDateStr } from "../../helpers";

const Timestamp = ({time, zone}) => {

  const timeLabel = generateDateStr(time, {
    dateStyle: "medium",
    timeStyle: "short",
    timeZone: zone,
  });

  return <Text size="xs" c="dimmed">{timeLabel}</Text>
};

export default Timestamp;