import { Group, Text, ThemeIcon, Menu, Skeleton, em, rem } from '@mantine/core';
import { useMediaQuery, useDisclosure } from '@mantine/hooks';
import { IconMap, IconMapOff, IconUser, IconLogout, IconUserPlus, IconHelp } from '@tabler/icons-react';

import AddUserModal from './Modals/AddUserModal';
import HelpModal from './Modals/HelpModal';

const Logout = () => {
  sessionStorage.removeItem('token');
  window.location.reload()
}

const HikeHeader = ({map_opened, toggle, hikename}) => {

  // configure map icon based on screen size
  const isMobile = useMediaQuery(`(max-width: ${em(750)})`);
  const mapIconColor = isMobile ? "black" : "lightgrey";
  const mapPointerType = isMobile ? "pointer" : "not-allowed";

  // get user information for the menu
  const tokenString = sessionStorage.getItem('token');
  const userToken = JSON.parse(tokenString);
  const isAdmin = userToken.isAdmin;

  // Modal form to add a new user
  const [form_opened, form_handlers] = useDisclosure(false);
  const [help_opened, help_handlers] = useDisclosure(false);
  
  return(
    <div style={{padding: "8px 0px"}}>
      <Group px="md" justify='space-between' gap="xl">
        
        <ThemeIcon opened={map_opened.toString()} onClick={toggle} size="xl" variant="white" color={mapIconColor} style={{cursor: mapPointerType}}>
          {map_opened || !isMobile ?
           <IconMapOff style={{ width: '70%', height: '70%' }} stroke={1.5} />
           :
           <IconMap style={{ width: '70%', height: '70%' }} stroke={1.5} />
          }
        </ThemeIcon>

        {hikename ?
          <Text size="lg">{hikename}</Text>
        :
          <div style={{width: isMobile ? "180px" : "240px" }}>
            <Skeleton height={16} radius="md" />
          </div>
        }
        
        <Menu shadow="md" width={200}>
          <Menu.Target>
            <ThemeIcon size="xl" variant="white" color="black" style={{cursor: "pointer"}} >
              <IconUser style={{ width: '70%', height: '70%' }} stroke={1.5}/>
            </ThemeIcon>
          </Menu.Target>

          <Menu.Dropdown>
            <Menu.Item disabled leftSection={<IconUser style={{ width: rem(14), height: rem(14) }} />}>
              {userToken.username}
            </Menu.Item>

            <Menu.Item 
              leftSection={<IconHelp style={{ width: rem(14), height: rem(14) }} />}
              onClick={help_handlers.open}
            >
              About
            </Menu.Item>

            {isAdmin &&
              <Menu.Item 
                leftSection={<IconUserPlus style={{ width: rem(14), height: rem(14) }} />}
                onClick={form_handlers.open}
                >
              Add User
              </Menu.Item>
            }

            <Menu.Item
              color="red"
              leftSection={<IconLogout style={{ width: rem(14), height: rem(14) }} />}
              onClick={Logout}
            >
              Log out
            </Menu.Item>
          </Menu.Dropdown>
        </Menu>
      </Group>
      <AddUserModal opened={form_opened} close={form_handlers.close}/>
      <HelpModal opened={help_opened} close={help_handlers.close}/>
    </div>
  );
};

export default HikeHeader;